<!-- Header -->
<div class="header">
    <p>{{ 'common.media-manager.documents.name' | translate }}</p>
    <p>{{ 'common.media-manager.documents.category' | translate }}</p>
    <p class="margin-right">{{ 'common.media-manager.documents.uploaded' | translate }}</p>
    <p></p>
</div>

<!-- Documents list -->
<div class="loading" *ngIf="fileService.loading$ | async; else ready">
    <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
</div>

<ng-template #ready>
    <div *ngIf="fileService.pdfs$ | async" class="information-list">
        <div class="list-item" *ngFor="let document of fileService.pdfs$ | async">
            <ng-container *ngIf="fileService.allowReportCreate$ | async as allowReportCreate">
                <div class="border-container" *ngIf="document && document.fileName">
                    <p class="link-look" (click)="openPdf(document)">{{ document.originalname }}</p>
                    <mat-form-field appearance="outline">
                        <mat-select (selectionChange)="onCategoryChange($event, document)" [value]="document.category"
                            [disabled]="!shouldDisplay()">
                            <mat-option *ngFor="let category of documentCategories" [value]="category"
                                [matTooltipDisabled]="disableToolTip(category, allowReportCreate)"
                                [matTooltip]="'common.media-manager.documents.tooltip_report_exists' | translate"
                                matTooltipPosition="right"
                                [disabled]="(allowReportCreate === 'false' && category === 'report')">
                                {{ 'common.media-manager.documents.' + category | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <p>{{ document.createdAt | date: 'yyyy-MM-dd' }}</p>
                    <mat-icon *ngIf="shouldDisplay()" (click)="removeDialog(document)">close</mat-icon>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<!-- documents list - footer, button -->
<div class="footer" *ngIf="shouldDisplay()">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

    <div class="file-upload">
        <button *ngIf="!uploadLoading" matButton mat-flat-button (click)="fileUpload.click()">
            <mat-icon>file_upload</mat-icon>
            {{ 'common.media-manager.documents.upload_document' | translate }}
        </button>
        <button *ngIf="uploadLoading" matButton mat-flat-button class="uploading-btn" class="uploading">
            <div>
                <mat-spinner diameter="20" class="spinner"></mat-spinner>
                <p>{{ 'common.media-manager.documents.uploading_file' | translate }}...</p>
            </div>
        </button>
    </div>
</div>
