import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, ReplaySubject, Subscription, take, tap } from 'rxjs';
import { DocumentFile } from 'src/app/shared/models/documentfile';
import { AbstractDocumentFile, FileCategories } from 'src/app/shared/types/documentfile';
import { environment } from 'src/environments/environment';
import { CachedSparseDataService } from '../../core/classes/cached.sparse.data.service';
import { ID } from '../../core/interfaces/model.interface';
import { NotificationService } from '../../core/services/notification.service';
import { FileTypeEntities } from '../components/media-manager/media-manager.component';
import { geoJsonWithData } from 'src/app/projects/components/project-dashboard/map-filter/map-filter.component';

@Injectable({
  providedIn: 'root'
})
export class FileService extends CachedSparseDataService<DocumentFile, DocumentFile> implements OnDestroy {

  private subscriptions = new Map<string, Subscription>();

  private $medias = new BehaviorSubject<DocumentFile[]>([]);
  public medias$ = this.$medias.asObservable();
  public setMedias(files: DocumentFile[]): void {
    this.$medias.next(files);
  }

  // Only allow 1 report per entity
  private $allowReportCreate = new BehaviorSubject<string>('false');
  public allowReportCreate$ = this.$allowReportCreate.asObservable();
  public setAllowReportCreate(allow: string): void {
    this.$allowReportCreate.next(allow);
  }

  private $loading = new BehaviorSubject<boolean>(false);
  public loading$ = this.$loading.asObservable();
  public setLoading(loading: boolean): void {
    this.$loading.next(loading);
  }

  public pdfs$ = this.medias$.pipe(
    map(x => x.filter((media) => media.documentType === "pdf")),
    map(x => {
      const reportExists = x.some(media => media.category === 'report')
      if (reportExists) {
        this.setAllowReportCreate('false')
      } else {
        this.setAllowReportCreate('true')
      }
      return x
    }),
  )

  public images$ = this.medias$.pipe(
    map(x => x.filter((media) => media.documentType === "image"))
  )

  public dwgs$ = this.medias$.pipe(
    map(x => x.filter((media) => media.documentType === "geojson"))
  )

  private _entityId$ = new ReplaySubject<string>(1);
  public get entityId$(): Observable<string> {
    return this._entityId$.asObservable().pipe(distinctUntilChanged())
  }

  public setEntityId(id: string): void {
    this._entityId$.next(id);
  }

  private _entityType$ = new ReplaySubject<FileTypeEntities>(1);
  public get entityType$(): Observable<string> {
    return this._entityType$.asObservable().pipe(distinctUntilChanged())
  }

  public setEntityType(type: FileTypeEntities): void {
    this._entityType$.next(type);
  }

  constructor(
    protected override http: HttpClient,
    protected notificationService: NotificationService
  ) {
    super(
      http,
      notificationService,
      'files',
      (input: DocumentFile) => new DocumentFile(input).deserialize(input, input.fileName),
      (input: DocumentFile | AbstractDocumentFile) => new DocumentFile(input).deserialize(input, input.fileName)
    );

    this.subscriptions.set(
      'id_type',
      combineLatest([
        this.entityId$,
        this.entityType$
      ]).pipe(debounceTime(100)).subscribe(([id, type]) => {
        this.$loading.next(true)
        this.getMediasForEntity(type, id).subscribe((files: DocumentFile[]) => {
          this.$medias.next(files);
          this.$loading.next(false)
        })
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  updateMediasObservable(entity: string, id: ID) {
    this.getMediasForEntity(entity, id)
      .pipe(take(1))
      .subscribe((data) => {
        this.$medias.next(data)
      })
  }

  getMediasForEntity(entity: string, id: ID): Observable<DocumentFile[]> {
    return this.requestGet({ resource: `${entity}/${id}/files`, id, addIdToResource: false })
  }

  updateMediaCategory(media: DocumentFile): Observable<{category: FileCategories}> {
    if (!media._id) {
      return of({ category: media.category || 'general' })
    }
    const payload = {
      category: media.category
    }

    return this.requestPatch(payload, { id: media._id, resource: `files` })
      .pipe(tap((updatedFile) => {
        // Update existing observable data
        const fullFile = updatedFile as DocumentFile
        const existingFiles = this.$medias.getValue()
        const updatedFiles = existingFiles.map(file => {
          if (file._id === fullFile._id) {
            file.category = fullFile.category
          }
          return file
        })
        this.$medias.next(updatedFiles)
        return updatedFile
      }))
  }

  patchDWG(media: DocumentFile): void {
    if (media._id) {
      const payLoad = {
        "showInProjectMap": media.showInProjectMap,
        "showInInitiativeMap": media.showInInitiativeMap
      }
      this.requestPatch(payLoad, { id: media._id, resource: `files` })
        .pipe(tap((updatedFile) => {
          // Update existing observable data
          const fullFile = updatedFile as DocumentFile
          const existingFiles = this.$medias.getValue()
          const updatedFiles = existingFiles.map(file => {
            if (file._id === fullFile._id) {
              file = fullFile
            }
            return file
          })
          this.$medias.next(updatedFiles)
        })).subscribe()
    }

  }


  create(media: AbstractDocumentFile, file: File): Observable<FormData | AbstractDocumentFile> {
    const fileData = new FormData();
    fileData.append("file", file);
    fileData.append("objectId", media.objectId);
    fileData.append("objectType", media.objectType);
    fileData.append("documentType", media.documentType);
    media.category && fileData.append("category", media.category);

    return this.requestCreate(fileData, { resource: `files/uploadfile`, addIdToResource: false })
      .pipe(tap((res) => {
        this.$medias.next([...this.$medias.value, res as unknown as DocumentFile])
      }))
  }

  remove(media: DocumentFile): Observable<DocumentFile> {
    if (!media.fileName) {
      return of(media)
    }
    return this.cachedDelete(media, { id: media.fileName }).pipe(tap((res) => {
      this.$medias.next(this.$medias.value.filter((doc) => doc.fileName !== res.fileName))
    }))
  }

  downloadPDF(fileName: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/v1/files/download/${fileName}?thumbnail=false`, { responseType: 'blob' as 'json' });
  }

  downloadGeoJson(fileName: string): Observable<geoJsonWithData> {
    return this.http.get<geoJsonWithData>(`${environment.API_BASE_URL}/v1/files/download/${fileName}?thumbnail=false`);
  }

  getAll() {
    return this.cachedGetAll();
  }
}
