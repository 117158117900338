import { ID } from "src/app/core/interfaces/model.interface"
import { FileTypeEntitiesSingle } from "../components/media-manager/media-manager.component"

export type FileType = 'image' | 'pdf' | 'other' | 'geojson'

export type FileObjectType = 'locations' | 'devices' | 'fieldworkAreas' | 'projects'

export type FileCategories = 'general' | 'calibration' | 'report' | 'manual'

export class AbstractDocumentFile {
  customerId?: string
  originalname?: string
  fileName?: string
  displayName?: string
  objectId: string
  objectType: FileTypeEntitiesSingle
  documentType: FileType
  mimetype?: string
  blobPath?: string
  thumbsPath?: string
  additionalProp1?: Record<string, unknown>
  createdAt?: Date
  updatedAt?: Date
  image?: string //used for slider
  category?: FileCategories;
  showInProjectMap?: boolean;
  showInInitiativeMap?: boolean;
  active?: boolean;
  _id?: ID

  constructor(input: AbstractDocumentFile) {
    this.image = input.image
    this.customerId = input.customerId;
    this.originalname = input.originalname;
    this.fileName = input.fileName;
    this.objectId = input.objectId;
    this.displayName = input.displayName;
    this.documentType = input.documentType;
    this.objectType = input.objectType;
    this.mimetype = input.mimetype;
    this.blobPath = input.blobPath;
    this.thumbsPath = input.thumbsPath;
    this.additionalProp1 = input.additionalProp1;
    this.createdAt = input.createdAt;
    this.updatedAt = input.updatedAt;
    this.category = input.category
    this._id = input._id
  }
}
